import React from "react";
import styles from "./SocialMediaIcons.module.css";
import { graphql, useStaticQuery } from "gatsby";
import { getImgUrl } from "../../shared/scripts";
import { Image } from "react-bootstrap";

const SocialMediaIcons = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "icons" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
  );
  return (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"https://www.facebook.com/fastpack.dk/"}
      >
        <Image
          className={styles.icon}
          title="Facebook"
          src={getImgUrl("facebook_black", allFile)}
        />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"https://www.instagram.com/fastpack.dk/"}
      >
        <Image
          className={styles.icon}
          title="Instagram"
          src={getImgUrl("instagram_black", allFile)}
        />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"https://pl.pinterest.com/fastpackdk/"}
      >
        <Image
          className={styles.icon}
          title="Pinterest"
          src={getImgUrl("pinterest_black", allFile)}
        />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
