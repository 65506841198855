import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styles from "./Post.module.css";
import Layout from "../../Layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../../context/GlobalContextProvider";
import MainImage from "./MainImage";
import AboutAuthor from "./AboutAuthor";
import SocialMediaIcons from "../../SocialMediaIcons";
import { CHANGE_LANGUAGE } from "../../../types";
import ArticleSchema from "../../../context/ArticleShema";

const Post = ({ data }) => {
  const { language } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { featuredImage, title, date, uri, content, article } = data.wpPost;
  useEffect(() => {
    if (uri.search("/dk/") > -1) {
      dispatch({ type: CHANGE_LANGUAGE, payload: "dk" });
    } else if (uri.search("/pl/") > -1) {
      dispatch({ type: CHANGE_LANGUAGE, payload: "pl" });
    } else if (uri.search("/es/") > -1) {
      dispatch({ type: CHANGE_LANGUAGE, payload: "es" });
    } else if (uri.search("/de/") > -1) {
      dispatch({ type: CHANGE_LANGUAGE, payload: "de" });
    } else if (uri.search("/fr/") > -1) {
      dispatch({ type: CHANGE_LANGUAGE, payload: "fr" });
    } else {
      dispatch({ type: CHANGE_LANGUAGE, payload: "en" });
    }
  }, [dispatch, uri]);
  return (
    <Layout>
      <ArticleSchema
        featuredImage={featuredImage}
        title={title}
        uri={uri}
        language={data.wpPost.language}
      />

      {featuredImage && (
        <MainImage
          featuredImage={featuredImage.node.sourceUrl}
          title={title}
          authorId={article}
          language={language}
          date={date}
        />
      )}
      <Container className={styles.main}>
        <Row className="justify-content-md-center">
          <SocialMediaIcons />
        </Row>
        <Row className={styles.postContent}>
          <Col
            md={{ span: 8, offset: 2 }}
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Row>

        {!!article?.author && (
          <div className={styles.author}>
            <AboutAuthor language={language} articleAuthor={article} />
          </div>
        )}
        <Row className={styles.social}>
          <SocialMediaIcons />
        </Row>
      </Container>
    </Layout>
  );
};

export default Post;

export const postQuery = graphql`
  query PostById($id: String) {
    wpPost(id: { eq: $id }) {
      date(locale: "")
      uri
      id
      title
      featuredImage {
        node {
          altText
          description
          sourceUrl
        }
      }
      language {
        slug
      }
      content
      article {
        author
        authorPhoto {
          title
          sourceUrl
        }
      }
    }
  }
`;
