export default {
  en: {
    title: "About the author",
    aw: {
      name: "Anna Wolthers",
      bio:
        "Copywriter and translator, she is passionate about design and cooking. Loves reading, sharing good meals with a group of friends and long walks in the park. Her duties at Fastpack include implementing marketing projects, copywriting, and managing social media campaigns."
    }
  },
  pl: {
    title: "O autorze",
    aw: {
      name: "Anna Wolthers",
      bio:
        "Autorka tekstów i tłumaczka. Dużo myśli, dużo czyta i dużo pisze. Do jej obowiązków w Fastpack należy między innymi wdrażanie projektów marketingowych, copywriting i tworzenie kampanii w mediach społecznościowych."
    }
  },
  dk: {
    title: "Om forfatteren",
    aw: {
      name: "Anna Wolthers",
      bio:
        "Er tekstforfatter af passion og erhverv. Hendes opgaver hos Fastpack indbefatter (men ikke kun begrænset til) implementering af marketingprojekter, copywriting samt styring af sociale mediekampagner. Hun stræber efter det kreative, men samtidig også at lave effektive marketingkampagner. I sin fritid, er hun fan af det skrevne i alle former – hvad enten det er klassikere eller sagprosa."
    }
  },
  es: {
    title: "About the author",
    aw: {
      name: "Anna Wolthers",
      bio:
        "Copywriter and translator, she is passionate about design and cooking. Loves reading, sharing good meals with a group of friends and long walks in the park. Her duties at Fastpack include implementing marketing projects, copywriting, and managing social media campaigns."
    }
  },
  de: {
    title: "About the author",
    aw: {
      name: "Anna Wolthers",
      bio:
        "Copywriter and translator, she is passionate about design and cooking. Loves reading, sharing good meals with a group of friends and long walks in the park. Her duties at Fastpack include implementing marketing projects, copywriting, and managing social media campaigns."
    }
  },
  fr: {
    title: "About the author",
    aw: {
      name: "Anna Wolthers",
      bio:
        "Copywriter and translator, she is passionate about design and cooking. Loves reading, sharing good meals with a group of friends and long walks in the park. Her duties at Fastpack include implementing marketing projects, copywriting, and managing social media campaigns."
    }
  }
};
