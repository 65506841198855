import React from "react";
import moment from "moment";
import { ButtonPrimary } from "../../../Buttons";
import postText from "../../blogData";
import authorData from "../../../../shared/blogAuthor";
import styles from "./MainImage.module.css";
import { graphql, useStaticQuery } from "gatsby";
import { Col } from "react-bootstrap";

const MainImage = ({
  title,
  authorId: { author, authorPhoto },
  language,
  mainPic,
  date,
  onClick,
}) => {
  const { button } = postText[language].post;
  return (
    <div className={styles.self}>
      <div
        className={styles.background}
        style={{ backgroundImage: `url(${mainPic})` }}
      />
      <Col className={styles.image}>
        <span>{moment(date).format("MMM Do YYYY")}</span>
        <h1>{title}</h1>
        <ButtonPrimary variant="outline-primary" to="blog" language={language}>
          {button}
        </ButtonPrimary>
      </Col>
      <div className={styles.author}>
        {authorPhoto && (
          <div
            className={styles.authorImage}
            style={{ backgroundImage: `url(${authorPhoto.sourceUrl})` }}
          />
        )}
        {author && <p>{author}</p>}
      </div>
    </div>
  );
};

export default MainImage;
