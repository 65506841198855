import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./AboutAuthor.module.css";
import authorData from "../../../../shared/blogAuthor";

const AboutAuthor = ({ language, articleAuthor: { author, authorPhoto } }) => {
  const { title } = authorData[language];

  return (
    <Row>
      <Col xs={{ span: 8, offset: 2 }}>
        <h4 className={styles.title}>{title}</h4>{" "}
      </Col>
      {authorPhoto && (
        <Col md={{ span: 2, offset: 2 }}>
          <div
            className={styles.authorImage}
            style={{ backgroundImage: `url(${authorPhoto.sourceUrl})` }}
          />
        </Col>
      )}
      {author && (
        <Col md={6}>
          <b>{author}</b>
        </Col>
      )}
    </Row>
  );
};

export default AboutAuthor;
